/* https://dev.to/5t3ph/my-top-css-tips-as-shared-on-twitter-part-1-23e2 */
/* https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/style.css */

html {
    background-color: var(--color-bg);
    color: var(--color-text);
    accent-color: var(--color); /* currently chromium canary */
    /* font-family: var(--font); old zzz */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    font-size: calc(13px + .7vmin);
    -webkit-hyphens: auto;
    hyphens: auto;
}
body {
    font-size:max(1rem, 16px);
}
a {
    color: var(--color);
    /* transition: color .2s; ff flickers on visited-color-change https://bugzilla.mozilla.org/show_bug.cgi?id=868975 */
}
a:visited {
    color: var(--color-text);
}
a:hover, a:focus {
    color: var(--color-text);
}
/* chars not widly supported
a[download], a[target] {
    text-decoration: none;
}
a[target]::after {
    content: '🠆';
    margin-left: .2em;
}
a[download]::after {
    content: ' 🠇'; /* 🠇⤵↓ *
    margin-left: .2em;
}
*/
hr {
    color: var(--color-line);
    margin: calc(var(--gap) * 2) 0;
    border-width:var(--line-width);
}


/* future: *
:where(h1,h2,h3,h4,h5,h6,p,ul,ol):first-child {
  margin-top:0;
}
:where(h1,h2,h3,h4,h5,h6,p,ul,ol):last-child {
  margin-bottom:0;
}
/* */
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child, ul:first-child, ol:first-child {
    margin-top:0;
}
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child, ul:last-child, ol:last-child {
    margin-bottom:0;
}
/* */

table {
    width: 100%;
    font-size: max(.65em, 14px);
}
tr {
    border-style:solid; /* new: not on td/th any problems? */
    border-width:0 0 var(--line-width) 0;
    border-color:var(--color-line);
}
td, th {
    padding: .5em;
    padding: calc(var(--gap) / 2);
    /*
    border-style:solid;
    border-width:0 0 var(--line-width) 0;
    border-color:var(--color-line);
    */
}

/* focus style */
:focus {
    outline: .4rem solid hsl(var(--hsl), .2);
    /* xoutline-offset:0; needed? */
    transition: .15s;
}
:focus:not(:focus-visible) { outline-width: 0; }

blockquote {
    margin: 0;
    padding: calc(var(--gap) / 2);
    padding-left: var(--gap);
    border-left: 5px solid var(--color);
    background-color: var(--color-light);
    border-radius: var(--radius);
}

pre {
    background-color: var(--color-light);
    padding: var(--gap);
    overflow-x: auto;
    border-radius: var(--radius);
}

code {
    font-size: 0.9em;
    padding: 0 0.5em;
    background-color: var(--color-light);
    white-space: pre-wrap;
    box-decoration-break: clone;
    line-height: 1;
    --line-height: 1;
}

mark {
    background-color: hsl(var(--hsl-h), 100%, 70%, 0.5);
    /*
    padding: 0 0.15em;  no padding as it can be some part of a word,
    maybe this? box-shadow: 0em 0em 0px 0.1em hsl(var(--hsl-h), 100%, 70%, 0.5) */
}

/* FORM */

/* all inputs, buttons */
input, textarea, select, button, ::file-selector-button, ::-webkit-file-upload-button, .btn {
    padding: .2em .4em;
    /* font-size: .9rem; */
    /* font-family: var(--font); zzz, was there a advantage over inherit? */
    font-family:inherit;
    max-width: 100%;
    /* todo: new ok? */
    /* xborder: 1px solid; */
    border-radius: var(--radius); /* remove? */
    /* min-height:1.3em; todo? for select, input same height in chrome, firefox has same height if they have borders? or line-height: 1.2;? line-height can not be set on select */
    border-width:var(--line-width);
    border-color:var(--color-line);
}


/* file-input */
[type=file] {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    border: none;
    background-color: var(--color-light);
}

/* buttons */
button, ::file-selector-button, ::-webkit-file-upload-button, .btn { /* The selector is not invalidated although ::-webkit-file-upload-button in ff is not working. */
    background-color: var(--color);
    color: var(--color-bg);
    border-color: transparent;
    padding-left: 1em;
    padding-right: 1em;
    transition: background-color .1s; /* better every property? does not get along with focus-animation */

    /* for .btn */
	text-decoration:none;
	display:inline-block;
}
button:hover:not([disabled]), :hover::file-selector-button, :hover::-webkit-file-upload-button, .btn:hover {
    background-color: var(--color-dark);
}
button[disabled] {
    opacity: .5;
}
::file-selector-button, ::-webkit-file-upload-button, select[size], /* chrome */ select[multiple] {
    border-top-right-radius: 0;/* recheck: border-end-end-radius supported? (-safari) */
    border-bottom-right-radius: 0;
    margin: 0 .5em 0 0;
}

:focus::placeholder {
    opacity: .2; /* default: .54 */
}



/* figure */
figure {
    padding:0;
    margin: var(--gap);
    /* font-size: 1.2rem; */
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    overflow:auto;
}
figure > img {
    border-radius: var(--radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
figure table {
    white-space: nowrap;
}
figcaption {
    opacity: .7;
    font-size: .9rem;
    font-style: italic;
    text-align: center;
}